// ============================
//  Library Imports
// ============================
import dotenv from "dotenv";

dotenv.config({ path: "./.env" });

const ENVIROMENT = process.env.REACT_APP_ENVIROMENT;

const getUrl = () => {
  let BASE_URL;

  switch (ENVIROMENT) {
    case "demo":
      BASE_URL = "https://fleet-apidemo.idoneo.com/api";
      break;
    case "test":
      BASE_URL = "https://fleet-apidev.idoneo.com/api";
      break;
    case "production":
      BASE_URL = "https://fleet-api.idoneo.com/api";
      break;
    default:
      BASE_URL = "http://127.0.0.1:8000/api";
      break;
  }

  return BASE_URL;
};

export default getUrl;
