// ============================
//  Libraries Imports
// ============================
import styled from 'styled-components'
import { useField } from 'formik'
import { useState } from 'react';

// ============================
//  Bootstrap Components
// ============================
import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
import InputGroup from 'react-bootstrap/InputGroup'

// ============================
//  FontAwesome Icons
// ============================
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

// ============================
//  Styled Components
// ============================
const StyledControl = styled(FormGroup)`
    margin: 2rem 0;
`
const StyledLabel = styled(FormLabel)`   
    float:left; 
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 100%;
    color: #323F4B;
    margin-left: 1rem;
    letter-spacing: 0.1rem;
`
const StyledInput = styled(FormControl)`
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    background: #fff;
    border-left: none;
    border-right: none;
    border-radius: 8px 0px 0px 8px;
    font-size: 1.6rem;
    line-height: 150%;
    color: #000000;
    height: 48px;    
    &::placeholder {
        font-weight: 500;
    }
`
const StyledIcon = styled(InputGroup.Text)`
    border-radius: 6px 0px 0px 6px;
    background-color: transparent;
    border-right: none;
    font-size: 1.5rem;
    position: relative;
    color: rgba(0,0,0,.55);
`

const StyledBackIcon = styled(InputGroup.Text)`
    margin-left: 0.4rem;
    background-color: transparent;
    border-radius: 0px 6px 6px 0px;
    border-left: none;
    font-size: 2rem;
    position: relative;
    color: rgba(0,0,0,.55);
`

const StyledErrorMessage = styled(FormControl.Feedback)`
text-align: left;
padding-left: 1.2rem;
`

const EyeOpen = styled(AiOutlineEye)`
    cursor: pointer;
`
const EyeClosed = styled(AiOutlineEyeInvisible)`
    cursor: pointer;
`
const StyledReq = styled.span`
    margin-left: 0.4rem;
    color: red;
`

// ============================
//  Principal Component
// ============================
const CommonInputPassword = ({ label, icon, required, ...props }) => {

    const [field, meta] = useField(props);
    const [isShown, setShown] = useState(false);
    return (
        <StyledControl>
            <StyledLabel>
                {label}
                {required ? <StyledReq>*</StyledReq> : <></>}
            </StyledLabel>
            <InputGroup hasValidation>
                <StyledIcon>
                    {icon}
                </StyledIcon>
                <StyledInput type={isShown ? "text" : "password"} {...field} {...props} isInvalid={meta.touched && meta.error} />
                <StyledBackIcon>
                    {isShown ? <EyeClosed onClick={() => setShown(false)} /> : <EyeOpen onClick={() => setShown(true)} />}
                </StyledBackIcon>
                {meta.touched && meta.error ? (
                    <StyledErrorMessage type="invalid">{meta.error}</StyledErrorMessage>
                ) : null}
            </InputGroup>
        </StyledControl>
    )
}

export default CommonInputPassword