// ============================
//  Libraries Imports
// ============================
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

// ============================
//  Custom Component Imports
// ============================
import App from './App';

// ============================
//  Styles Imports
// ============================
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { commonContext,CommonProvider } from './context/commonContext';
import { useContext } from "react";
import LoadingGif from './assets/loading.gif'
import styled from 'styled-components'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const Image = styled.img`
  position:absolute; 
  left:50%; 
  top:50%; 
  transform:translate(-50%,-50%);
`
serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();
//reportWebVitals(console.log);

// ============================
//  Main Component Rendered
// ============================
ReactDOM.render(
  <CommonProvider>
      <React.StrictMode>
        <Suspense fallback={<Image src={LoadingGif} alt="Loading..." />}>
          <App />
        </Suspense>
      </React.StrictMode>
  </CommonProvider>
  ,
  document.getElementById('root')
)
