// ============================
//  Libraries Imports
// ============================
import styled from 'styled-components'
import Image from 'react-bootstrap/Image'

const StyledImage = styled(Image)`
    margin-bottom: 1rem;
`

// ============================
//  Principal Component
// ============================
const LoginImage = ({ ...props }) => {

    return (
        <StyledImage {...props} />
    )
}

export default LoginImage