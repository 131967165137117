// ============================
//  Libraries Imports
// ============================
import { useContext } from "react";
import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import Swal from 'sweetalert2'
import Device from "../Common/breakPoint";

// ============================
//  Commons Components
// ============================
import CommonInput from "../Common/CommonInput";

// ============================
//  Custom Component Imports
// ============================
import LoginContainer from "./LoginContainer";
import LoginSection from "./LoginSection";
import LoginImage from "./LoginImage";
import LoginForm from "./LoginForm";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";

import CommonInputPassword from "../Common/CommonInputPassword";
import CommonPrimaryButton from "../Common/CommonPrimaryButton";

import { FormattedMessage, useIntl } from "react-intl";
import { commonContext } from "../../context/commonContext";

// ============================
//  FontAwesome Icons
// ============================
import {FaRegEnvelope} from 'react-icons/fa';
import {FiLock} from 'react-icons/fi';
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

import CommonIcon from "../Common/CommonIcon";

// ============================
//  Other Imports
// ============================
import loginUser from "../../services/Auth/loginUser";
import Img from "../../assets/img/idoneo_fleet.png";

const StyledP = styled.p`
  font-family: "Poppins", sans-serif;
  text-decoration: underline;
  color:#2296B9;
  font-weight: 400;
  background: #fff;
  font-size: 1.2rem;
  &:hover {
    cursor: pointer;
  }
`;
const StyledButtonFlag = styled(Button)`
  font-family: "Open Sans", sans-serif;
  border: 2px solid #2ba6cb;
  border-radius: 8px;
  cursor: pointer;
  background: transparent;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 7rem;
  margin: 0.5rem;
  display: inline-block;
  &:hover {
    border: none;
    color: #ffffff;
    background: #2ba6cb;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.24), 0 5px 5px 0 rgba(0, 0, 0, 0.19);
  }
  &:focus {
    background: transparent;
    border: none;
    color: #000;
  }
`;

const StyledDivFlag = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
`;

const StyledDivButton = styled.div`
  position: relative;
  bottom: 0;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  gap: 20vw;
  margin-bottom: 2rem;

  @media only screen and ${Device.lg} {
    margin-top: 4rem;
    position: relative;
  }

  @media only screen and ${Device.xl} {
    margin-top: 4rem;
    position: relative;
  }

  @media only screen and ${Device.xxl} {
    margin-top: 4rem;
    position: relative;
  }
`;
const StyledDropdown = styled(Dropdown.Toggle)`
  padding: 1rem;
  margin: 1rem;
  font-size: 2rem;
  line-height: 1;
  background-color: transparent;
  border: none;
  :after {
    display: none;
  }
`;

// ============================
//  Principal Component
// ============================
const Login = ({ setToken }) => {
  const intl = useIntl();

  const context = useContext(commonContext);
  const schema = Yup.object().shape({
    username: Yup.string().trim()
      .min(5, intl.formatMessage({ id: "login.check.minimum" }))
      .max(255, intl.formatMessage({ id: "login.check.maximum" }))
      .required(intl.formatMessage({ id: "login.check.user" })),
    password: Yup.string().trim()
      .min(5, intl.formatMessage({ id: "login.check.minimum" }))
      .max(255, intl.formatMessage({ id: "login.check.maximum" }))
      .required(intl.formatMessage({ id: "login.check.password" })),
  });

  const Restore = (e) => {
    localStorage.setItem("loginopass", "pass");
    window.location.assign("/");
  };

  const handleSubmit = async (values) => {
    let fetch = await loginUser(values, "login");

    if (fetch.flag === true) {
      setToken(fetch.result);

      context.setLanguage(fetch.result.locale);
      const queryParams = new URLSearchParams(window.location.search);
      const id_token = queryParams.get("id_token");
      queryParams.delete("id_token");
      const paramsString = queryParams.toString();
      if(paramsString !==""){
        var parameters = "?"+paramsString;
      }else{
        var parameters = "";
      }
      window.location.assign(window.location.pathname+parameters);
    } else {
      Swal.fire({
        title: intl.formatMessage({ id: "login.denied.title" }),
        text: intl.formatMessage({ id: fetch.result.message }),
        icon: "error",
        confirmButtonColor: '#3085d6',
      });
    }
  };

  return (
    <LoginContainer fluid>
      <LoginSection>
        <LoginImage src={Img} alt="img-login" fluid />
        <Formik
          initialValues={{ username: "", password: "" }}
          validationSchema={schema}
          onSubmit={(values) => handleSubmit(values)}
        >
          <LoginForm>
            <CommonInput
              type="text"
              label={
                <FormattedMessage
                  id="login.username"
                  defaultMessage="username"
                />
              }
              name="username"
              placeholder={intl.formatMessage({
                id: "login.placeholder.username",
              })}
              icon={<FaRegEnvelope/>}
            />
            <CommonInputPassword
              label={
                <FormattedMessage
                  id="login.password"
                  defaultMessage="password"
                />
              }
              name="password"
              placeholder={intl.formatMessage({
                id: "login.placeholder.password",
              })}
              icon={<FiLock/>}
            />
            <CommonPrimaryButton type="submit" widthxs="100">
              <FormattedMessage id="login.button" defaultMessage="Login" />
            </CommonPrimaryButton>
          </LoginForm>
        </Formik>
        <StyledDivButton>
          <StyledP onClick={Restore}>
            <FormattedMessage
              id="login.repass"
              defaultMessage="Forgot password? Click for restore"
            />
          </StyledP>
        </StyledDivButton>
        <StyledDivFlag>
          <StyledButtonFlag onClick={() => context.setLanguage("es-ES")}>
            <FormattedMessage id="language.spanish" defaultMessage="Spanish" />
          </StyledButtonFlag>
          <StyledButtonFlag onClick={() => context.setLanguage("en-UK")}>
            <FormattedMessage id="language.english" defaultMessage="English" />
          </StyledButtonFlag>

          {/* <Dropdown drop="start" id="dropdown-menu-align-start">
            <StyledDropdown
              className="navbar-toggler"
              variant="link"
              bsPrefix="p-0"
            > Language
              <CommonIcon icon={faAngleDown} />
            </StyledDropdown>
            <Dropdown.Menu>
              <Dropdown.Item>
                <StyledButtonFlag onClick={() => language.setLanguage("es-ES")}>
                  <FormattedMessage id="language.spanish" defaultMessage="Spanish" />
                </StyledButtonFlag>
              </Dropdown.Item>
              <Dropdown.Item>
                <StyledButtonFlag onClick={() => language.setLanguage("en-UK")}>
                  <FormattedMessage id="language.english" defaultMessage="English" />
                </StyledButtonFlag>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}

        </StyledDivFlag>
      </LoginSection>
    </LoginContainer>
  );
};

// ============================
// Props and Exports
// ============================
Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default Login;
