import { Route } from "react-router";
import { useContext, lazy } from "react";
import { commonContext } from "../../../context/commonContext";
// ============================
//  Forbidden
// ============================
const Forbidden = lazy(() => import("../../../components/Forbidden/Forbidden"));

const PrivateRoute = ({
  path,
  component: Component,
  notfound: NotFound,
  rolout,
  ...rest
}) => {
  const context = useContext(commonContext);
  const inRole = rolout.includes(context.user.role);
  return (
    <Route {...path} {...rest}>
      {inRole ? (
        (context.user.status === 99 && path !== "/onboarding") ||
        (context.user.status !== 99 && path === "/onboarding") ? (
          <NotFound />
        ) : (
          <Component />
        )
      ) : (
        <Forbidden />
      )}
    </Route>
  );
};

export default PrivateRoute;
