
// ============================
//  Ohers Imports
// ============================
import getUrl from '../../helpers/config'

const url = getUrl()

const loginUser = async (data, uri, method = 'POST') => {

    let dataFetched

    if(localStorage.getItem("lang") !== null){
        data['locale'] = localStorage.getItem("lang");
    }
    
    const fetching = await fetch(`${url}/${uri}`,
        {
            method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
    )

    switch (fetching.status) {
        case 200:
            dataFetched = {
                flag: true,
                result: await fetching.json()
            }
            break;
        case 401:
            dataFetched = {
                flag: false,
                result: await fetching.json()
            }
            break;

        default:
            break;
    }

    return dataFetched
}

export default loginUser