import Swal from 'sweetalert2'

function ErrorSwal(code, title, message, icon = "error", result, intl, context) {

    switch (code) {
        case 400:
        case 401:
            //Cuidado al modificar esto que no afecte al resto de los componentes
            if (title != "" && message != "") {
                if (result.code == "00008") {
                    var arr = [];
                    Object.keys(result.errors).forEach(function (key) {
                        arr[key] = result.errors[key][0];
                    });
                    var text = [];
                    Object.keys(arr).forEach((key) => {
                        text.push(intl.formatMessage({ id: arr[key] }, { field: key })); //);
                    });
                    message = text.join("\n");
                } else {
                    message = intl.formatMessage({ id: message });
                }

                Swal.fire({
                    title: intl.formatMessage({ id: title }),
                    text: message,
                    icon: icon,
                    confirmButtonColor: "#3085d6",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                }).then(function () {
                    if (result.code === "00009") {
                        localStorage.removeItem("token");
                        window.location.assign("/");
                    }

                    if (result.code === "00010") {
                        window.location.assign("/");
                    }
                });

            } else {
                console.log(result)
            }
            break;
        case 404:
            Swal.fire({
                title: intl.formatMessage({ id: "swal.common.error" }),
                text: intl.formatMessage({ id: "swal.common.404" }),
                icon: icon,
                confirmButtonColor: "#3085d6",
                allowOutsideClick: false,
                allowEscapeKey: false,
            }).then(function () {
                window.location.assign("/home");
            });
            //console.log(result)
            break;
        case 500:
            Swal.fire({
                title: intl.formatMessage({ id: "swal.common.error" }),
                text: intl.formatMessage({ id: "swal.common.500" }),
                icon: icon,
                confirmButtonColor: "#3085d6",
                allowOutsideClick: false,
                allowEscapeKey: false,
            }).then(function () {
                window.location.assign("/home");
            });
            //console.log(result)
            break;
        case 501:
            context.swal501.fire().then(function () {
                localStorage.removeItem("token")
                window.location.assign('/')
            });
            break;
        case 505:
            //Cuidado al modificar esto que no afecte al resto de los componentes
            if (title != "" && message != "") {
                if (result.code == "00008") {
                    var arr = [];
                    Object.keys(result.errors).forEach(function (key) {
                        arr[key] = result.errors[key][0];
                    });
                    var text = [];
                    Object.keys(arr).forEach((key) => {
                        text.push(intl.formatMessage({ id: arr[key] }, { field: key })); //);
                    });
                    message = text.join("\n");
                } else {
                    message = intl.formatMessage({ id: message });
                }

                Swal.fire({
                    title: intl.formatMessage({ id: title }),
                    text: message,
                    icon: icon,
                    confirmButtonColor: "#3085d6",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                }).then(function () {
                    if (result.code === "00009") {
                        localStorage.removeItem("token");
                        window.location.assign("/");
                    }
                });

            } else {
                console.log(result)
            }
            break;
        default:
            console.log(result)
            break;
    }

}

export default ErrorSwal;


