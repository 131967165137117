// ============================
//  Libraries Imports
// ============================
import styled from 'styled-components'
import Button from 'react-bootstrap/Button'
import Device from "../Common/breakPoint";
// ============================
//  Styled Components
// ============================
const StyledButton = styled(Button)`
    border-color: transparent;
    border-radius: 8px;
    border: 8px;
    background: linear-gradient(180deg, #2BA6CB 0%, #1988A9 100%);
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-size: 15px;
    font-weight: bold;
    line-height: 100%;
    margin: ${(props) => props.width  ? '1rem 0.5rem;' : '0rem;'};
    height: 58px;

    width:  ${(props) => props.width ? props.width : 100}%;
    @media only screen and ${Device.xs} {
        width:  ${(props) => props.widthxs ? props.widthxs : 40}%;
        margin: 1rem auto;
      }
`

const CommonPrimaryButton = ({ ...props }) => {
    return <StyledButton {...props} />
}

export default CommonPrimaryButton