// ============================
//  Libraries Imports
// ============================
import styled from 'styled-components'

// ============================
//  Customs Imports
// ============================
import Device from '../../Common/breakPoint'

// ============================
//  Styled Components
// ============================
const StyledSection = styled.section`
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 20px 25px;
  width: 30rem;
  box-shadow: 0px 2px 3px rgb(0,0,0,0.3);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  display:  flex;
  @media only screen and ${Device.xs}{
    box-shadow: none;
    margin: 0;
    min-height: 100vh;
    width: 100%;
  }

  @media only screen and ${Device.sm}{
    box-shadow: none;
    margin: 0;
    min-height: 100vh;
    width: 100%;
  }

  @media only screen and ${Device.lg}{
    box-shadow: none;
    min-height: 512px;
    min-width: 680px;
  }

  @media only screen and ${Device.xl}{
    box-shadow: none;
  }

  @media only screen and ${Device.xxl}{
    box-shadow: none;
  }
`
// ============================
//  Principal Component
// ============================
const LoginSection = ({children}) => {
  return (
    <StyledSection>
      {children}
    </StyledSection>
  )
}

export default LoginSection