// ============================
//  Libraries Imports
// ============================
import React from "react";
import styled from "styled-components";

import { useEffect } from "react";
// ============================
//  Commons Components
// ============================

// ============================
//  Custom Component Imports
// ============================
import CommonPrimaryButton from "../../Common/CommonPrimaryButton";
import ConfirmpassContainer from "./ConfirmpassContainer";
import ConfirmpassSection from "./ConfirmpassSection";
import ConfirmpassImage from "./ConfirmpassImage";

import { FormattedMessage } from "react-intl";

// ============================
//  FontAwesome Icons
// ============================

// ============================
//  Other Imports
// ============================
import Confirmpass from "../../../services/Auth/Confirmpass";
import Img from "../../../assets/img/repass/repass2.svg";
import Row from "react-bootstrap/Row";

const StyledBody = styled(Row)`
  margin: 0.5rem;
  justify-content: center;
`;
const Styledh1 = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 150%;
  letter-spacing: 0.5px;
`;

// ============================
//  Principal Component
// ============================

const ConfirmpassFrom = () => {

  useEffect(() => {
    getToken();
  }, []);

  const getToken = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const id_token = queryParams.get("id_token");
    queryParams.delete("id_token");
    //const paramsString = queryParams.toString();
    if (id_token !== null) {
      let fetch = await Confirmpass(id_token, "confirmpass");
    }else{
      window.location.assign("/");
    }
  };
 
  const Login = (e) => {
    window.location.assign("/");
  };

  return (
    <ConfirmpassContainer fluid>
      <ConfirmpassSection>
        <StyledBody>
          <Styledh1>
            <FormattedMessage
              id="repass.info2.title"
              defaultMessage="Password restore successful"
            />
          </Styledh1>
          <p>
            <FormattedMessage
              id="repass.info2.description"
              defaultMessage="Please go to the login and try with the password sended to your email."
            />
          </p>
        </StyledBody>
        <ConfirmpassImage src={Img} alt="img-login" fluid />

        <CommonPrimaryButton onClick={Login}>
          <FormattedMessage
            id="login.button.confirmpass"
            defaultMessage="Go to Login"
          />
        </CommonPrimaryButton>
      </ConfirmpassSection>
    </ConfirmpassContainer>
  );
};

export default ConfirmpassFrom;
