// ============================
//  Ohers Imports
// ============================
import getUrl from "../../helpers/config";

const url = getUrl();
let status = 0;
let data;

const getIdoneoContactByToken = async (uri, method = "GET") => {
  let dataFetched;
  await fetch(`${url}/${uri}`, {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then((response) => {
      status = response.status;
      if (status !== 500) {
        return response.json();
      }
    })
    .then((recurso) => {
      if (recurso) {
        data = recurso.Contact;
      }
    })
    .catch(function (error) {
      console.log("Catch");
      console.log(error);
    });

  switch (status) {
    case 200:
    case 201:
      dataFetched = {
        code: status,
        flag: true,
        result: await data,
      };
      break;
    case 400:
    case 401:
      dataFetched = {
        code: status,
        flag: false,
        result: await data,
      };
      break;
    case 500:
      dataFetched = {
        code: status,
        flag: false,
        result: await data,
      };
      break;
    case 501:
      dataFetched = {
        code: status,
        flag: false,
        result: await data,
      };
      break;
    default:
      dataFetched = {
        code: status,
        flag: false,
        result: await data,
      };
      break;
  }

  return dataFetched;
};

export default getIdoneoContactByToken;