
// ============================
//  Ohers Imports
// ============================
import getUrl from "../../helpers/config";
import Swal from 'sweetalert2';

const axios = require("axios");
const url = getUrl();
var status = 0;

const updateLastAccess = async (values, uri, method = 'POST') => {

    let dataFetched
    let data = new FormData();

    //Importacion de tipo excel, mapeo de nombre de los campos

    if(values.last_access){data.append("last_access", values.last_access);}else{data.append("last_access", "");}

    await axios
    .post(`${url}/${uri}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      if (response) {
        status = response.status;
        data = response.data;
      }
    })
    .catch(function (error) {
      console.log('Catch');
      if (error.response) {
        status = error.response.status;
        data = error.response.data;
      }
    });

  switch (status) {
    case 200:
    case 201:
      dataFetched = {
        code: status,
        flag: true,
        result: data,
      };
      break;
    case 400:
    case 401:
      dataFetched = {
        code: status,
        flag: false,
        result: data,
      };
      break;
    case 500:
      dataFetched = {
        code: status,
        flag: false,
        result: data,
      };
      break;
    case 501:
      Swal.fire({
        title: "Whoops!", 
        text: "You must be logged in to access your fleet", 
        icon: "error",
        confirmButtonColor: '#3085d6',
      }).then(function () {
        localStorage.removeItem("token")
        window.location.assign('/')
      })

      dataFetched = {
        code: status,
        flag: false,
        result: data
      }
      break;
    default:
      dataFetched = {
        code: status,
        flag: false,
        result: data
      }
      break;
  }

  return dataFetched
}

export default updateLastAccess