// ============================
//  Libraries Imports
// ============================
import { useState } from 'react'

// ============================
//  Principal Hook
// ============================
const useToken = () => {

    const getToken = () => {
        const tokenString = localStorage.getItem('token')
        const userToken = tokenString;
        return userToken
    }

    const [token, setToken] = useState(getToken())

    const saveToken = (userToken)  => {
        localStorage.setItem('token', userToken.access_token)
        setToken(userToken.access_token)
    }

    return {
        setToken: saveToken,
        token
    }
}

export default useToken