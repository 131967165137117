// ============================
//  Libraries Imports
// ============================
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'

// ============================
//  Customs Imports
// ============================
import Device from '../../Common/breakPoint'

// ============================
//  Styled Components
// ============================
const StyledContainer = styled(Container)`
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
 
    height: 100vh;

  @media only screen and ${Device.xs}{
    box-shadow: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  @media only screen and ${Device.sm}{
    box-shadow: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  @media only screen and ${Device.lg}{
    box-shadow: none;
    height: 100%;
    width: auto;
  }

  @media only screen and ${Device.xl}{
    box-shadow: none;
    height: 100%;
    width: auto;
  }
`
// ============================
//  Principal Component
// ============================
const LoginContainer = ({ children, ...props }) => {

  return (
    <StyledContainer {...props}>
      {children}
    </StyledContainer>
  )
}


export default LoginContainer