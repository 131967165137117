
// ============================
//  Ohers Imports
// ============================
import getUrl from '../../helpers/config'
import Swal from 'sweetalert2'

const axios = require("axios")
const url = getUrl()
var status = 0
const loginUser = async (data, uri, method = 'POST') => {
    let values = new FormData()
    let dataFetched
    values.append("id_token", data)

    await axios
        .post(`${url}/${uri}`, values, {
            method,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            body: JSON.stringify(values)
        })
        .then((response) => {
            if (response) {
                status = response.status
                data = response.data
            }
        })
        .catch(function (error) {
            console.log('Catch');
            if (error.response) {
                status = error.response.status;
                data = error.response.data;
            }
        })

    switch (status) {
        case 200:
        case 201:
            dataFetched = {
                code: status,
                flag: true,
                result: data,
            }
            break
        case 400:
        case 401:
            dataFetched = {
                code: status,
                flag: false,
                result: data,
            }
            break
        case 500:
            dataFetched = {
                code: status,
                flag: false,
                result: data,
            }
            break
        case 501:
            
            Swal.fire({
                title: "Whoops!", 
                text: "You must be logged in to access your fleet", 
                icon: "error",
                confirmButtonColor: '#3085d6',
            }).then(function () {
                localStorage.removeItem("token")
                window.location.assign('/')
            })
            
            dataFetched = {
                code: status,
                flag: false,
                result: data
            }
            break
        default:
            dataFetched = {
                code: status,
                flag: false,
                result: data
            }
            break
    }

    return dataFetched
}

export default loginUser