import React from "react";
import LoadingGif from '../../assets/loading.gif'
import styled from 'styled-components'

const Image = styled.img`
  position:absolute; 
  left:50%; 
  top:50%; 
  transform:translate(-50%,-50%);
`

const Loading = () => (
  <div >
      <Image src={LoadingGif} alt="Loading..." />
  </div>
);

export default Loading;