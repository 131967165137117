// ============================
//  Libraries Imports
// ============================
import styled from 'styled-components'

// ============================
//  Styled Components
// ============================
const StyledIcons = styled.span`
    font-size: ${(props)=> (props.size ? props.size : '2rem')};
    min-width: 1em;
    min-height: 1em;
    color: ${(props) => (props.color ? props.color : 'rgba(0,0,0,.55)')};
`

// ============================
//  Principal Component
// ============================
const CommonIcon = ({icon, ...props}) => {
    return (
        <StyledIcons {...props}>
            {icon}
        </StyledIcons>
    )
}

export default CommonIcon