// ============================
//  Libraries Imports
// ============================
import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import Swal from "sweetalert2";
import Device from "../Common/breakPoint";

// ============================
//  Commons Components
// ============================
import CommonInput from "../Common/CommonInput";
import CommonPrimaryButton from "../Common/CommonPrimaryButton";

// ============================
//  Custom Component Imports
// ============================
import RepassContainer from "./RepassContainer";
import RepassSection from "./RepassSection";
import RepassImage from "./RepassImage";
import RepassForm from "./RepassForm";

import { FormattedMessage, useIntl } from "react-intl";

// ============================
//  FontAwesome Icons
// ============================
import { faAt } from "@fortawesome/free-solid-svg-icons";

// ============================
//  Other Imports
// ============================
import Repass from "../../services/Auth/Repass";
import Img from "../../assets/img/repass/repass1.svg";
import Row from "react-bootstrap/Row";
const StyledP = styled.p`
  text-decoration: underline;
  color: #2296b9;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background: #fff;
  font-size: 1.2rem;
  &:hover {
    cursor: pointer;
  }
`;

const StyledDivButton = styled.div`
  position: relative;
  bottom: 0;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  gap: 20vw;
  margin-bottom: 2rem;
  @media only screen and ${Device.lg} {
    margin-top: 2rem;
    position: relative;
  }

  @media only screen and ${Device.xl} {
    margin-top: 2rem;
    position: relative;
  }

  @media only screen and ${Device.xxl} {
    margin-top: 2rem;
    position: relative;
  }
`;

const StyledBody = styled(Row)`
  margin: 0.5rem;
  justify-content: center;
`;
const Styledh1 = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 150%;
  letter-spacing: 0.5px;
`;

// ============================
//  Principal Component
// ============================

const RepassFrom = () => {
  const intl = useIntl();

  // const [email, setEmail] = useState();
  // const [reemail, setReemail] = useState();

  const schema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .min(5, intl.formatMessage({ id: "login.check.minimum" }))
      .max(255, intl.formatMessage({ id: "login.check.maximum" }))
      .required(intl.formatMessage({ id: "login.check.user" })),
  });

  const Restore = (e) => {
    localStorage.setItem("loginopass", "login");
    window.location.assign("/");
  };

  const handleSubmit = async (values) => {
    let fetch = await Repass(values, "repass");
    let message = fetch.result.message;
    if (fetch.flag) {
      localStorage.setItem("loginopass", "login");
      Swal.fire({
        title: intl.formatMessage({ id: "swal.common.success" }),
        text: intl.formatMessage({ id: message }),
        icon: "success",
        timer: 2000,
        showConfirmButton: false, allowOutsideClick: false,
      }).then(function (result) {
        if (result.dismiss === Swal.DismissReason.timer) {
          window.location = "/";
        }
      });
    } else {
      Swal.fire({
        title: intl.formatMessage({ id: "swal.common.error" }),
        text: intl.formatMessage({ id: message }),
        icon: "error",
        confirmButtonColor: "#3085d6",
      });
    }
  };

  return (
    <RepassContainer fluid>
      <RepassSection>
        <StyledBody>
          <Styledh1>
            <FormattedMessage
              id="repass.info1.title"
              defaultMessage="Forgot password?"
            />
          </Styledh1>
          <p>
            <FormattedMessage
              id="repass.info1.description"
              defaultMessage="Dont warry, we can help."
            />
          </p>
        </StyledBody>
        <RepassImage src={Img} alt="img-login" fluid />
        <Formik
          initialValues={{ email: "" }}
          validationSchema={schema}
          onSubmit={(values) => handleSubmit(values)}
        >
          <RepassForm>
            <CommonInput
              type="text"
              label={
                <FormattedMessage id="login.email" defaultMessage="email" />
              }
              name="email"
              placeholder={intl.formatMessage({
                id: "login.placeholder.email",
              })}
              icon={<faAt></faAt>}
            />
            <CommonPrimaryButton type="submit" width="100" widthxs="100">
              <FormattedMessage
                id="repass.button"
                defaultMessage="Restore Password"
              />
            </CommonPrimaryButton>
          </RepassForm>
        </Formik>
        <StyledDivButton>
          <StyledP onClick={Restore}>
            <FormattedMessage id="repass.back" defaultMessage="Back to Login" />
          </StyledP>
        </StyledDivButton>
      </RepassSection>
    </RepassContainer>
  );
};

export default RepassFrom;
