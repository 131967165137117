// ============================
//  Libraries Imports
// ============================
import styled from 'styled-components'
import { useField } from 'formik'

// ============================
//  Bootstrap Components
// ============================
import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
import InputGroup from 'react-bootstrap/InputGroup'

// ============================
//  Styled Components
// ============================
const StyledControl = styled(FormGroup)`
    margin: 2rem 0;
`
const StyledLabel = styled(FormLabel)`   
    float:left; 
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 100%;
    color: #323F4B;
    margin-left: 1rem;
    letter-spacing: 0.1rem;
`
const StyledInput = styled(FormControl)`
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    background: #fff;
    border-left: none;
    border-radius: 8px;
    font-size: 1.6rem;
    line-height: 150%;
    color: #000000;
    height: 48px;    
    &::placeholder {
        font-weight: 500;
    }
`
const StyledIcon = styled(InputGroup.Text)`    
    border-radius: 6px 0px 0px 6px;
    background-color: transparent;
    border-right: none;
    font-size: 1.5rem;   
    left: 0.4rem;
    color: rgba(0,0,0,.55);
`
const StyledErrorMessage = styled(FormControl.Feedback)`
    text-align: left;
    padding-left: 1.2rem;
`
const StyledReq = styled.span`
    margin-left: 0.4rem;
    color: red;
`
const StyledDiv = styled.div`
    text-align: left;
`
const StyledLabelUnder = styled(FormLabel)`
    padding-left: 1.2rem;
    font-family: 'Poppins', sans-serif;
    font-style: italic;
    font-weight: 500;
    font-size: 1.1rem;
    color: #65707A;
    letter-spacing: 0.1rem;
`
// ============================
//  Principal Component
// ============================
const CommonInput = ({ label, icon, anyicon, required, under, ...props }) => {

    const [field, meta] = useField(props);

    return (
        <StyledControl>
            <StyledLabel>
                {label}
                {required ? <StyledReq>*</StyledReq> : <></>}
            </StyledLabel>
            <InputGroup hasValidation>
                <StyledIcon>
                    {icon ? icon : anyicon}
                </StyledIcon>
                <StyledInput {...field} {...props} isInvalid={meta.touched && meta.error} />
                {meta.touched && meta.error ? (
                    <StyledErrorMessage type="invalid">{meta.error}</StyledErrorMessage>
                ) : null}
            </InputGroup>
            {under ?
                <StyledDiv>
                    <StyledLabelUnder>
                        {under}
                    </StyledLabelUnder>
                </StyledDiv>
                : <></>
            }
        </StyledControl>
    )
}

export default CommonInput