import { useState, createContext } from "react";
import { IntlProvider } from "react-intl";
import EnglishMessages from "../lang/en-UK.json";
import SpanishMessages from "../lang/es-ES.json";
import MexicoMessages from "../lang/es-MX.json";
import ArgentinaMessages from "../lang/es-AR.json";
import Swal from "sweetalert2";

const commonContext = createContext();
const CommonProvider = ({ children }) => {
  let localeByDefault;
  let messagesByDefault;
  const lang = localStorage.getItem("lang") || "es-ES";

  if (lang) {
    localeByDefault = lang;

    if (lang === "es-ES") {
      messagesByDefault = SpanishMessages;
    } else if (lang === "en-UK") {
      messagesByDefault = EnglishMessages;
    } else if (lang === "es-AR") {
      messagesByDefault = ArgentinaMessages;
    } else if (lang === "es-MX") {
      messagesByDefault = MexicoMessages;
    } else {
      localeByDefault = "en-UK";
      messagesByDefault = EnglishMessages;
    }
  }

  const [messages, setMessagges] = useState(messagesByDefault);
  const [locale, setLocale] = useState(localeByDefault);

  const setLanguage = (language) => {
    switch (language) {
      case "es-ES":
        setMessagges(SpanishMessages);
        setLocale("es-ES");
        localStorage.setItem("lang", "es-ES");
        break;
      case "en-UK":
        setMessagges(EnglishMessages);
        setLocale("en-UK");
        localStorage.setItem("lang", "en-UK");
        break;
      case "es-AR":
        setMessagges(ArgentinaMessages);
        setLocale("es-AR");
        localStorage.setItem("lang", "es-AR");
        break;
      case "es-MX":
        setMessagges(MexicoMessages);
        setLocale("es-MX");
        localStorage.setItem("lang", "es-MX");
        break;
      default:
        setMessagges(EnglishMessages);
        setLocale("en-UK");
        localStorage.setItem("lang", "en-UK");
        break;
    }
  };
  const [currentUser, setCurrentUser] = useState({
    id: null,
    name: null,
    img: null,
    role: null,
    status: null,
    locale: null
  });

  const setUser = (user) => {
    setCurrentUser(user);
  };

  const updateSwal501 = (error) => {
    swal501.update(error);
  };

  var [commonValues, setCommonValues] = useState({
    vehicle_id: null,aux: null,
  });

  const setCValues = (values) => {
    setCommonValues(values);
  };

  var [accountCurrency, setAccountCurrency] = useState({
    icon: "€",
    name: "EUR"
  });

  const setCurrency = (values) => {
    setAccountCurrency(values);
  };

  var swal501 = Swal.mixin({
    title: (lang === 'es-ES' ? "Ups!" : "Whoops!"),
    text: (lang === 'es-ES' ? "Debes estar logueado para acceder a tu flota" : "You must be logged in to access your fleet"),
    icon: "error",
    confirmButtonColor: '#3085d6',
    allowOutsideClick: false,
    allowEscapeKey: false,
  });

  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
  });

  const setLocationData = (values) => {
    window.navigator.geolocation.getCurrentPosition(auxSetLocationData);
  }

  const auxSetLocationData = (position) => {
    setLocation({latitude: position.coords.latitude, longitude: position.coords.longitude});
  }

  const [idoneo, setIdoneo] = useState({
    id: null,
    name: null,
    img: null,
    email: null,
    phone: null
  });

  const setIdoneoContact = (user) => {
    setIdoneo(user);
  };

  const [liters, setLiters] = useState(0);
  
  const setIHistoryL = (l) => {
    setLiters(l);
  };
  const [cost, setCost] = useState(0);
  const setIHistoryC = (c) => {
    setCost(c);
  };
  return (
    <commonContext.Provider value={
      { setLanguage: setLanguage,
        user: currentUser,
        setUser: setUser,
        swal501,
        setCValues: setCValues,
        cValues:commonValues,
        lang: locale,
        currency: accountCurrency,
        setCurrency: setCurrency,
        location: location,
        setLocation: setLocationData,
        idoneoContact: idoneo,
        setIdoneoContact: setIdoneoContact,
        liters: liters,
        setIHistoryL: setIHistoryL,
        cost: cost,
        setIHistoryC: setIHistoryC,
      }
      }>
      <IntlProvider
        locale={locale}
        messages={messages}
        timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
      >
        {children}
      </IntlProvider>
    </commonContext.Provider>
  );
};

export { CommonProvider, commonContext };
