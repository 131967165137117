// ============================
//  Libraries Imports
// ============================
import "bootstrap/dist/css/bootstrap.css";
import React, { lazy, useEffect, useState, useContext } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { commonContext } from "./context/commonContext";
import { useIntl } from "react-intl";

import PrivateRoute from "./components/Common/PrivateRoutes/PrivateRoute";

import getDataByToken from "./services/User/getDataByToken";
import updateLastAccess from "./services/User/updateLastAccess";
import getIdoneoContactByToken from "./services/Account/getIdoneoContactByToken";
import ErrorSwal from "./components/Common/Swal/ErrorSwal";

// ============================
//  Custom Component Imports
// ============================
import Login from "./components/Login/Login";
import Loading from "./components/Common/Loading";
import Repass from "./components/Repass/Repass";
import ConfirmPass from "./components/Repass/Confirmpass/Confirmpass";

// ============================
//  Cumstom Hooks Imports
// ============================
import useToken from "./hooks/useToken";
import loginUser from "./services/Auth/loginUser";

//general
const NotFound = lazy(() => import("./components/NotFound/NotFound"));
const Home = lazy(() => import("./components/Home/Home"));
const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));
const Configuration = lazy(() =>
  import("./components/Configuration/Configuration")
);
const IdoneoContact = lazy(() => import("./components/Idoneo/IdoneoContact"));
const Manager = lazy(() => import("./components/Manager/Manager"));
const AccountActivityList = lazy(() =>
  import("./components/Activity/ActivityAdmin/AccountActivityList")
);

//carsharing
const Pruebas = lazy(() => import("./components/Common/CommonMaps"));

//carsharing
const AddCarsharing = lazy(() =>
  import("./components/Carsharing/CarsharingAdmin/AddCarsharing/AddCarsharing")
);
const EditCarsharing = lazy(() =>
  import(
    "./components/Carsharing/CarsharingAdmin/EditCarsharing/EditCarsharing"
  )
);
const EditAvailability = lazy(() =>
  import(
    "./components/Carsharing/CarsharingAdmin/EditAvailability/EditAvailability"
  )
);
const AddAvailability = lazy(() =>
  import(
    "./components/Carsharing/CarsharingAdmin/AddAvailability/AddAvailability"
  )
);
const AddBooking = lazy(() =>
  import("./components/Carsharing/CarsharingDriver/AddBooking/AddBooking")
);
const CarsharingAdmin = lazy(() =>
  import("./components/Carsharing/CarsharingAdmin/CarsharingAdmin")
);
const CarsharingDriver = lazy(() =>
  import("./components/Carsharing/CarsharingDriver/CarsharingDriver")
);
const DataBooking = lazy(() =>
  import("./components/Carsharing/CarsharingDriver/DataBooking/DataBooking")
);

//consumo
const Consumo = lazy(() => import("./components/Consumo/Consumo"));
const DataConsumo = lazy(() =>
  import("./components/Consumo/DataConsumo/DataConsumo")
);
const CVehicle = lazy(() => import("./components/Consumo/Vehicle"));
const CMileage = lazy(() => import("./components/Consumo/Mileage"));
const CRefueling = lazy(() => import("./components/Consumo/Refueling"));

//contact
const Contact = lazy(() => import("./components/Contact/Contact"));
const ContactDriver = lazy(() => import("./components/Contact/Driver/Contact"));
const AddContact = lazy(() =>
  import("./components/Contact/AddContact/AddContact")
);
const AssignContact = lazy(() =>
  import("./components/Contact/AssignContact/AssignContact")
);
const DataContact = lazy(() =>
  import("./components/Contact/DataContact/DataContact")
);
const DataContactA = lazy(() =>
  import("./components/Contact/DataContactA/DataContactA")
);

const UpdateContact = lazy(() =>
  import("./components/Contact/UpdateContact/UpdateContact")
);

const ContactCar = lazy(() =>
  import("./components/Contact/ContactCar/ContactCar")
);

//drivers
const Drivers = lazy(() => import("./components/Drivers/Drivers"));
const DriversSA = lazy(() => import("./components/Drivers/SuperAdmin/Drivers"));

//excel import
//const MatchTable = lazy(() => import("./components/ExcelImport/MatchTable"));
const ExcelImport = lazy(() =>
  import("./components/ExcelImport/pages/ExcelImport")
);
const Importer = lazy(() => import("./components/ExcelImport/pages/Importer"));
/*
const ImportPreview = lazy(() =>
  import("./components/ExcelImport/pages/ImportPreview")
);
*/
const TableExample = lazy(() =>
  import("./components/ExcelImport/pages/TableExample")
);
//expenses
const AddExpense = lazy(() =>
  import("./components/Expenses/AddExpense/AddExpense")
);
const DataExpenses = lazy(() =>
  import("./components/Expenses/DataExpense/DataExpenses")
);
const UpdateExpense = lazy(() =>
  import("./components/Expenses/UpdateExpense/UpdateExpense")
);
const Expenses = lazy(() => import("./components/Expenses/Expenses"));

//fichar
const FicharVehicle = lazy(() =>
  import("./components/FicharVehicle/FicharVehicle")
);
const DriveVehicleQr = lazy(() =>
  import("./components/FicharVehicle/DriveVehicleQr")
);

//failure
const FailureAdmin = lazy(() =>
  import("./components/Failure/FailureAdmin/Failure")
);
const FailureUser = lazy(() =>
  import("./components/Failure/FailureUser/Failure")
);
const FailuresData = lazy(() =>
  import("./components/Failure/FailureData/FailureData")
);
const FailuresListFleet = lazy(() =>
  import("./components/Failure/FailuresListFleet/FailuresListFleet")
);
const FailureEdit = lazy(() =>
  import("./components/Failure/FailureEdit/FailureEdit")
);

const FailureDocuments = lazy(() =>
  import("./components/Failure/FailureDocumentation/Documentation")
);

const AddFailureDocuments = lazy(() =>
  import(
    "./components/Failure/FailureDocumentation/UploadDocumentation/UploadDocumentation"
  )
);

const FailureGallery = lazy(() =>
  import("./components/Failure/FailureGallery/Images")
);

const AddFailureImages = lazy(() =>
  import("./components/Failure/FailureImages/UploadImages/UploadImages")
);

const FailureImages = lazy(() =>
  import("./components/Failure/FailureImages/Images")
);

const FailuresListVehicle = lazy(() =>
  import("./components/Failure/FailuresListVehicle/FailuresListVehicle")
);

//fines
const FinesA = lazy(() => import("./components/Fine/FineAdmin/FinesA"));

const FinesAddA = lazy(() =>
  import("./components/Fine/FineAdmin/AddFine/AddFineA")
);
const FinesAddD = lazy(() =>
  import("./components/Fine/FineDriver/AddFine/AddFineD")
);
const FinesDataA = lazy(() =>
  import("./components/Fine/FineAdmin/DataFine/DataFineA")
);
const FinesDataD = lazy(() =>
  import("./components/Fine/FineDriver/DataFine/DataFineD")
);
const VehicleFinesD = lazy(() =>
  import("./components/Fine/FineDriver/VehicleFines/VehicleFinesD")
);
const VehicleFinesA = lazy(() =>
  import("./components/Fine/FineAdmin/VehicleFines/VehicleFinesA")
);

//form
const FormStartBooking = lazy(() =>
  import("./components/Form/StartBooking/StartBooking")
);

//mileage
const Mileage = lazy(() => import("./components/Mileage/Mileage"));
/*
const MileageList = lazy(() =>
  import("./components/Mileage/MileageList/MileageList")
);
const MileagesListVehicle = lazy(() =>
import("./components/Mileage/MileagesListVehicle/MileageList")
);
const MileagesListUser = lazy(() =>
import("./components/Mileage/MileagesListUser/MileageList")
);
*/

//help
const Help = lazy(() => import("./components/Help/Help.js"));
const HelpVideo = lazy(() => import("./components/Help/HelpVideo.js"));

//notifications
const Notifications = lazy(() =>
  import("./components/Notifications/Notifications")
);
const NotificationsAdmin = lazy(() =>
  import("./components/Notifications/NotificationsAdmin")
);

//onboarding
const GestorOnboarding = lazy(() =>
  import("./components/GestorOnboarding/Onboarding")
);

const GestorCampaign = lazy(() =>
  import("./components/GestorOnboarding/Campaign/Onboarding")
);
// const GestorOBMantenimiento = lazy(() =>
//   import("./components/GestorOnboarding/Mantenimiento/Onboarding")
// );
// const GestorOBEbook = lazy(() =>
//   import("./components/GestorOnboarding/Ebook/Onboarding")
// );

const Onboarding = lazy(() => import("./components/Onboarding/Onboarding"));

//onboarding info
const InfoOnboarding = lazy(() =>
  import("./components/InfoOnboarding/InfoOnboarding")
);
const GestorInfoOnboarding = lazy(() =>
  import("./components/GestorInfoOnboarding/InfoOnboarding")
);

//QR
const GenerateQr = lazy(() => import("./components/GenerateQr/GenerateQr"));
const GenerateUserQr = lazy(() =>
  import("./components/GenerateQr/GenerateUserQr")
);

//refueling
const Refueling = lazy(() => import("./components/Refueling/Refueling"));
/*
const RefuelingList = lazy(() =>
  import("./components/Refueling/RefuelingList/RefuelingList")
);
const RefuelingsListVehicle = lazy(() =>
  import("./components/Refueling/RefuelingsListVehicle/RefuelingsList")
);
const RefuelingsListUser = lazy(() =>
  import("./components/Refueling/RefuelingsListUser/RefuelingsList")
);
*/
//road
const AddRoad = lazy(() => import("./components/RoadAdmin/AddRoad/AddRoad"));
const DataRoad = lazy(() => import("./components/RoadAdmin/DataRoad/DataRoad"));
const Road = lazy(() => import("./components/Road/Road"));
const RoadAdmin = lazy(() => import("./components/RoadAdmin/Road"));
const RoadCar = lazy(() => import("./components/Road/RoadCar/RoadCar"));

//usages
const UsagesHistoryA = lazy(() =>
  import("./components/Usages/UsagesAdmin/UsagesHistoryA")
);

//user
const AddUser = lazy(() => import("./components/User/AddUser/AddUser"));
const AddUserSA = lazy(() =>
  import("./components/User/SuperAdmin/AddUser/AddUser")
);
const DataUser = lazy(() => import("./components/User/DataUser/DataUser"));
const DataUserSA = lazy(() =>
  import("./components/User/SuperAdmin/DataUser/DataUser")
);
const HistoryUser = lazy(() =>
  import("./components/User/HistoryUser/HistoryUser")
);
const License = lazy(() => import("./components/User/License/License"));
const Personal = lazy(() => import("./components/User/Personal/Personal"));
const Profile = lazy(() => import("./components/User/Profile/Profile"));
const UserDni = lazy(() =>
  import("./components/User/DataUser/IdentityCard/IdentityCard")
);
const UserLicense = lazy(() =>
  import("./components/User/DataUser/License/License")
);

const UpdateIdentityCard = lazy(() =>
  import("./components/User/UpdateIdentityCard/UpdateIdentityCard")
);
const UpdateLicense = lazy(() =>
  import("./components/User/UpdateLicense/UpdateLicense")
);
const UpdatePassword = lazy(() =>
  import("./components/User/UpdatePassword/UpdatePassword")
);
const UpdateUser = lazy(() =>
  import("./components/User/UpdateUser/UpdateUser")
);
const UpdateUserSA = lazy(() =>
  import("./components/User/SuperAdmin/UpdateUser/UpdateUser")
);

const Unsubscribed = lazy(() =>
  import("./components/User/UnsuscribedUser/Unsuscribed")
);
//vehicles
const AddVehicle = lazy(() =>
  import("./components/Vehicles/AddVehicle/AddVehicle")
);
const AddVehicleSA = lazy(() =>
  import("./components/Vehicles/SuperAdmin/AddVehicle/AddVehicle")
);
const AssignVehicle = lazy(() =>
  import("./components/Vehicles/AssignVehicle/AssignVehicle")
);
const DataVehicle = lazy(() =>
  import("./components/Vehicles/DataVehicle/DataVehicle")
);
const DataVehicleSA = lazy(() =>
  import("./components/Vehicles/SuperAdmin/DataVehicle/DataVehicle")
);
const RentingDataVehicle = lazy(() =>
  import("./components/Vehicles/RentingDataVehicle/RentingDataVehicle")
);
const UpdateVehicle = lazy(() =>
  import("./components/Vehicles/UpdateVehicle/UpdateVehicle")
);
const UpdateVehicleSA = lazy(() =>
  import("./components/Vehicles/SuperAdmin/UpdateVehicle/UpdateVehicle")
);
const AssignDriver = lazy(() =>
  import("./components/Vehicles/AssignDriver/AssignDriver")
);
const DriverRefuel = lazy(() =>
  import("./components/Vehicles/MyVehicles/Refuel/DataRefuel.js")
);
/*
const EquipmentsListVehicle = lazy(() =>
  import("./components/Vehicles/Equipment/EquipmentsList")
);
*/
const HistoryVehicle = lazy(() =>
  import("./components/Vehicles/HistoryVehicle/HistoryVehicle")
);
const MyVehicles = lazy(() =>
  import("./components/Vehicles/MyVehicles/Vehicles")
);
const MyDataVehicle = lazy(() =>
  import("./components/Vehicles/MyVehicles/DataVehicle/DataVehicle")
);
/*
const MyDataVehicleAdmin = lazy(() =>
  import("./components/Vehicles/MyVehicles/AdminDataVehicle/DataVehicle")
);
const SecondsVehicles = lazy(() =>
  import("./components/Vehicles/SecondVehicles/SecondVehiclesList")
);
*/
const Documentation = lazy(() =>
  import("./components/Vehicles/Documentation/Documentation")
);
const DocumentationAdmin = lazy(() =>
  import("./components/Vehicles/AdminDocumentation/Documentation")
);
const UploadDocumentation = lazy(() =>
  import(
    "./components/Vehicles/AdminDocumentation/UploadDocumentation/UploadDocumentation"
  )
);
const Vehicles = lazy(() => import("./components/Vehicles/Vehicles"));
const VehiclesSA = lazy(() =>
  import("./components/Vehicles/SuperAdmin/Vehicles")
);
const ResumenConsumo = lazy(() =>
  import("./components/Vehicles/MyVehicles/ResumenConsumo/ResumenConsumo")
);

//smartcar
const SmartCarDataInfo = lazy(() =>
  import("./components/SmartCar/SmartCarDataInfo")
);
const PlanAndTerms = lazy(() => import("./components/SmartCar/PlanAndTerms"));

//WorkshopDate //descomentar
//fines
const WorkshopDatesA = lazy(() =>
  import("./components/WorkshopDate/WorkshopDateAdmin/WorkshopDatesA")
);
const AddWorkshopDateA = lazy(() =>
  import(
    "./components/WorkshopDate/WorkshopDateAdmin/AddWorkshopDate/AddWorkshopDateA"
  )
);
const DataWorkshopDateA = lazy(() =>
  import(
    "./components/WorkshopDate/WorkshopDateAdmin/DataWorkshopDate/DataWorkshopDateA"
  )
);
const WorkshopDatesVehicle = lazy(() =>
  import("./components/WorkshopDate/WorkshopDateAdmin/WorkshopDatesVehicle")
);
const DataWorkshopDateD = lazy(() =>
  import(
    "./components/WorkshopDate/WorkshopDateDriver/DataWorkshopDate/DataWorkshopDateD"
  )
);

//Inspections
//fines
const InspectionsA = lazy(() =>
  import("./components/Inspections/InspectionAdmin/InspectionsA")
);
const AddInspectionA = lazy(() =>
  import(
    "./components/Inspections/InspectionAdmin/AddInspection/AddInspectionA"
  )
);
const DataInspectionA = lazy(() =>
  import(
    "./components/Inspections/InspectionAdmin/DataInspection/DataInspectionA"
  )
);
const DataInspectionD = lazy(() =>
  import(
    "./components/Inspections/InspectionDriver/DataInspection/DataInspectionD"
  )
);
const VehicleInspectionsA = lazy(() =>
  import(
    "./components/Inspections/InspectionAdmin/VehicleInspection/VehicleInspectionsA"
  )
);
const VehicleInspectionsD = lazy(() =>
  import(
    "./components/Inspections/InspectionDriver/VehicleInspection/VehicleInspectionsD"
  )
);

//reports
const HomeReports = lazy(() =>
  import("./components/Reports/Admin/HomeReports")
);

const VehicleReports = lazy(() =>
  import("./components/Reports/Admin/Vehicle/VehicleReports")
);
const CostEvolution = lazy(() =>
  import("./components/Reports/Admin/Vehicle/CostEvolution")
);
const CostPerKm = lazy(() =>
  import("./components/Reports/Admin/Vehicle/CostPerKm")
);
const UseVehicles = lazy(() =>
  import("./components/Reports/Admin/Vehicle/UseVehicles")
);
const FuelReports = lazy(() =>
  import("./components/Reports/Admin/Fuel/FuelReports")
);
const VehiclesFuelReports = lazy(() =>
  import("./components/Reports/Admin/Fuel/VehiclesFuelReports")
);

const FailureReports = lazy(() =>
  import("./components/Reports/Admin/Failure/FailureReports")
);
const InspectionsReports = lazy(() =>
  import("./components/Reports/Admin/Inspections/InspectionsReports")
);

const RepairReports = lazy(() =>
  import("./components/Reports/Admin/Repair/RepairReports")
);
const UserReports = lazy(() =>
  import("./components/Reports/Admin/User/UserReports")
);

//reports driver

const HomeReportsDriver = lazy(() =>
  import("./components/Reports/Driver/HomeReports")
);
const DriverFuel = lazy(() =>
  import("./components/Reports/Driver/Fuel/DriverFuel")
);

//alerts
const Alerts = lazy(() => import("./components/Alerts/Alerts"));

//SmartCarDataInfo

// ============================
//  Principal Component
// ============================
const App = () => {
  const intl = useIntl();
  const context = useContext(commonContext);

  const { token, setToken } = useToken();

  const [getToken, setLocalToken] = useState(localStorage.getItem("token"));
  const loginopass = localStorage.getItem("loginopass");
  const href = window.location.pathname;

  const [loadUser, setLoadUser] = useState(false);

  const [getLastAccess, setLastAccess] = useState(
    sessionStorage.getItem("lastAccess")
  );

  useEffect(() => {
    async function modifyLastAccess() {
      if (getLastAccess === null) {
        const fecha = new Date();
        const last_access =
          fecha.getFullYear() +
          "-" +
          fecha.getMonth() +
          "-" +
          fecha.getDay() +
          " " +
          fecha.getHours() +
          ":" +
          fecha.getMinutes() +
          ":" +
          fecha.getSeconds();
        let fetch = await updateLastAccess(
          { last_access: last_access },
          "users/updateLastAccess"
        );
        //console.log(fetch);
        if (fetch.flag === true) {
          setLastAccess(last_access);
          sessionStorage.setItem("lastAccess", last_access);
        }
      }
    }

    modifyLastAccess();
  }, []);

  const JSONData = {
    code: "00009",
    message: "swal.blockade",
  };

  useEffect(() => {
    let isMounted = true;
    async function cargarUsuario() {
      // Si viene por URL el Token, es decir, desde Email
      const queryParams = new URLSearchParams(window.location.search);
      const id_token = queryParams.get("id_token");
      const src = queryParams.get("src");
      queryParams.delete("id_token");
      queryParams.delete("src");
      const paramsString = queryParams.toString();
      var parameters = "";
      if (paramsString !== "") {
        parameters = "?" + paramsString;
      }

      if (id_token !== null && window.location.pathname !== "/confirmpass") {
        let fetch = await loginUser({ idToken: id_token, src: src }, "login");
        if (fetch.flag === true) {
          context.setLanguage(fetch.result.locale);

          localStorage.setItem("token", fetch.result.access_token);
          setLocalToken(fetch.result.access_token);
          window.location.assign(window.location.pathname + parameters);
        }
      }
      //---------------------------------------------------//
      if (!getToken) {
        setLoadUser(false);
        return () => {
          isMounted = false;
        };
      }

      let response = await getDataByToken(`users/myDataByToken`);
      if (response.flag === true) {
        if (response.result.User) {
          let r = response.result.User.rol;
          var arr_role = [];
          for (var clave in r) {
            if (r.hasOwnProperty(clave)) {
              arr_role.push(r[clave]);
            }
          }
          if (
            context.idoneoContact != null &&
            context.idoneoContact.id == null &&
            arr_role[0] == "Admin"
          ) {
            let idoneocontact = await getIdoneoContactByToken(
              `account/getIdoneoContactByToken`
            );
            if (idoneocontact.flag === true) {
              if (idoneocontact.result != null) {
                //console.log("ponemos idoneo contac")
                context.setIdoneoContact({
                  id: idoneocontact.result.id,
                  name: idoneocontact.result.description,
                  email: idoneocontact.result.email,
                  phone: idoneocontact.result.phone,
                  img: idoneocontact.result.img,
                });
              }
            }
          }
          context.setUser({
            id: response.result.User.id,
            name: response.result.User.name,
            img: response.result.User.img_profile
              ? response.result.User.img_profile_url
              : null,
            role: arr_role[0],
            status: response.result.User.status,
            locale: response.result.User.locale,
            campaign: response.result.Account.campaign,
          });
        }
        if (response.result.Account) {
          const currency = [
            { id: "AFN", name: "AFN ؋" },
            { id: "ALL", name: "ALL L" },
            { id: "DZD", name: "DZD د.ج" },
            { id: "AOA", name: "AOA Kz" },
            { id: "XCD", name: "XCD $" },
            { id: "SAR", name: "SAR ﷼" },
            { id: "ARS", name: "ARS $" },
            { id: "AMD", name: "AMD" },
            { id: "AWG", name: "AWG ƒ" },
            { id: "EUR", name: "EUR €" },
            { id: "AZN", name: "AZN" },
            { id: "BSD", name: "BSD $" },
            { id: "BDT", name: "BDT ৳" },
            { id: "BBD", name: "BBD $" },
            { id: "BHD", name: "BHD .د.ب" },
            { id: "BMD", name: "BMD" },
            { id: "BZD", name: "BZD $" },
            { id: "BYR", name: "BYR Br" },
            { id: "MMK", name: "MMK K" },
            { id: "BOB", name: "BOB Bs" },
            { id: "BOV", name: "BOV" },
            { id: "BAM", name: "BAM KM" },
            { id: "BWP", name: "BWP P" },
            { id: "BRL", name: "BRL $" },
            { id: "BND", name: "BND $" },
            { id: "BGN", name: "BGN лв" },
            { id: "BIF", name: "BIF Fr" },
            { id: "BTN", name: "BTN Nu" },
            { id: "CVE", name: "CVE $" },
            { id: "KHR", name: "KHR ៛" },
            { id: "CAD", name: "CAD $" },
            { id: "CLF", name: "CLF" },
            { id: "CLP", name: "CLP $" },
            { id: "CNY", name: "CNY ¥" },
            { id: "COP", name: "COP $" },
            { id: "COU", name: "COU" },
            { id: "KMF", name: "KMF Fr" },
            { id: "CDF", name: "CDF Fr" },
            { id: "CRC", name: "CRC ₡" },
            { id: "HRK", name: "HRK kn" },
            { id: "CUC", name: "CUC" },
            { id: "CUP", name: "CUP $" },
            { id: "EGP", name: "EGP £" },
            { id: "SVC", name: "SVC ₡" },
            { id: "AED", name: "AED د.إ" },
            { id: "ERN", name: "ERN Nfk" },
            { id: "USN", name: "USN" },
            { id: "ETB", name: "ETB Br" },
            { id: "FJD", name: "FJD" },
            { id: "PHP", name: "PHP ₱" },
            { id: "XDR", name: "XDR" },
            { id: "GMD", name: "GMD D" },
            { id: "GEL", name: "GEL ლ" },
            { id: "GHS", name: "GHS ₵" },
            { id: "GTQ", name: "GTQ Q" },
            { id: "GIP", name: "GIP £" },
            { id: "GNF", name: "GNF Fr" },
            { id: "GYD", name: "GYD" },
            { id: "HTG", name: "HTG G" },
            { id: "HNL", name: "HNL L" },
            { id: "HKD", name: "HKD" },
            { id: "HUF", name: "HUF Ft" },
            { id: "INR", name: "INR ₹" },
            { id: "IDR", name: "IDR Rp" },
            { id: "IQD", name: "IQD ع.د" },
            { id: "ISK", name: "ISK kr" },
            { id: "KYD", name: "KYD $" },
            { id: "DKK", name: "DKK kr" },
            { id: "FKP", name: "FKP" },
            { id: "SBD", name: "SBD $" },
            { id: "ILS", name: "ILS ₪" },
            { id: "JMD", name: "JMD $" },
            { id: "JPY", name: "JPY" },
            { id: "JOD", name: "JOD د.ا" },
            { id: "KZT", name: "KZT ₸" },
            { id: "KES", name: "KES Sh" },
            { id: "KGS", name: "KGS лв" },
            { id: "KWD", name: "KWD د.ك" },
            { id: "LSL", name: "LSL L" },
            { id: "LRD", name: "LRD $" },
            { id: "LYD", name: "LYD ل.د" },
            { id: "MGA", name: "MGA Ar" },
            { id: "MYR", name: "MYR RM" },
            { id: "MWK", name: "MWK MK" },
            { id: "MVR", name: "MVR .ރ" },
            { id: "MUR", name: "MUR ₨" },
            { id: "MRO", name: "MRO UM" },
            { id: "MNT", name: "MNT ₮" },
            { id: "MZN", name: "MZN MT" },
            { id: "MXN", name: "MXN $" },
            { id: "MXV", name: "MXV" },
            { id: "NAD", name: "NAD $" },
            { id: "NPR", name: "NPR ₨" },
            { id: "NIO", name: "NIO C$" },
            { id: "NGN", name: "NGN ₦" },
            { id: "NZD", name: "NZD $" },
            { id: "NOK", name: "NOK kr" },
            { id: "XPF", name: "XPF Fr" },
            { id: "OMR", name: "OMR ر.ع." },
            { id: "XUA", name: "XUA" },
            { id: "PKR", name: "PKR Rs" },
            { id: "PAB", name: "PAB B/." },
            { id: "PGK", name: "PGK K" },
            { id: "PYG", name: "PYG ₲" },
            { id: "PEN", name: "PEN S/" },
            { id: "PLN", name: "PLN zł" },
            { id: "QAR", name: "QAR ر.ق" },
            { id: "GBP", name: "GBP £" },
            { id: "LAK", name: "LAK ₭" },
            { id: "VEF", name: "VEF BsF" },
            { id: "XAF", name: "XAF Fr" },
            { id: "CZK", name: "CZK Kč" },
            { id: "KRW", name: "KRW ₩" },
            { id: "MDL", name: "MDL L" },
            { id: "KPW", name: "KPW ₩" },
            { id: "DOP", name: "DOP $" },
            { id: "IRR", name: "IRR ﷼" },
            { id: "TZS", name: "TZS Sh" },
            { id: "SYP", name: "SYP ل.س" },
            { id: "RWF", name: "RWF Fr" },
            { id: "RON", name: "RON L" },
            { id: "RUB", name: "RUB ₽" },
            { id: "MAD", name: "MAD د.م." },
            { id: "WST", name: "WST T" },
            { id: "ANG", name: "ANG ƒ" },
            { id: "STD", name: "STD Db" },
            { id: "SHP", name: "SHP" },
            { id: "RSD", name: "RSD дин" },
            { id: "SCR", name: "SCR ₨" },
            { id: "SLL", name: "SLL ₨" },
            { id: "SGD", name: "SGD $" },
            { id: "XSU", name: "XSU" },
            { id: "SOS", name: "SOS Sh" },
            { id: "LKR", name: "LKR Rs" },
            { id: "SZL", name: "SZL L" },
            { id: "ZAR", name: "ZAR R" },
            { id: "SDG", name: "SDG £" },
            { id: "SSP", name: "SSP £" },
            { id: "SEK", name: "SEK kr" },
            { id: "CHE", name: "CHE" },
            { id: "CHF", name: "CHF Fr" },
            { id: "CHW", name: "CHW" },
            { id: "SRD", name: "SRD $" },
            { id: "THB", name: "THB ฿" },
            { id: "TWD", name: "TWD $" },
            { id: "TJS", name: "TJS ЅМ" },
            { id: "USD", name: "USD $" },
            { id: "XOF", name: "XOF Fr" },
            { id: "TOP", name: "TOP T$" },
            { id: "TTD", name: "TTD 	$" },
            { id: "TMT", name: "TMT m" },
            { id: "TRY", name: "TRY" },
            { id: "AUD", name: "AUD $" },
            { id: "TND", name: "TND د.ت" },
            { id: "UGX", name: "UGX Sh" },
            { id: "UAH", name: "UAH ₴" },
            { id: "UYI", name: "UYI" },
            { id: "UYU", name: "UYU $" },
            { id: "UZS", name: "UZS лв" },
            { id: "VUV", name: "VUV Vt" },
            { id: "VND", name: "VND ₫" },
            { id: "YER", name: "YER ﷼" },
            { id: "DJF", name: "DJF" },
            { id: "ZMW", name: "ZMW" },
            { id: "ZWL", name: "ZWL $" },
          ];
          let accCurrency = currency.filter(
            (item) => item.id == response.result.Account.currency
          )[0].name;
          context.setCurrency({
            icon: accCurrency.split(" ").pop().trim(),
            name: accCurrency.split(" ").shift().trim(),
          });
        }

        setLoadUser(false);
      } else {
        ErrorSwal(
          response.code,
          "",
          "",
          "error",
          response.result,
          intl,
          context
        );
      }
    }

    cargarUsuario();
  }, []);

  const SuperAdmin = ["SuperAdmin"];
  const Admin = ["Admin"];
  const Driver = ["Driver"];
  const All = ["SuperAdmin", "Admin", "Driver"];
  let user_id = ":id";

  //URLS
  let permission_role = "";
  let url_onboarding = "";
  let url_infoOnboarding = "";
  let url_road = "";
  let url_doc = "";
  let url_carsharing = "";
  let url_drivers = "";
  let url_vehicles = "";
  let url_vehicles_add = "";
  let url_vehicles_data = "";
  let url_vehicles_update = "";
  let url_user_add = "";
  let url_user_data = "";
  let url_user_update = "";
  let url_fines = "";
  let url_vehicle_fines = "";
  let url_fines_add = "";
  let url_fines_data = "";
  let url_contact = "";
  let url_failure = "";

  let url_wsdates = "";
  let url_wsdatesVehicle = "";
  let url_wsdates_data = "";
  let url_wsdates_add = "";
  let url_inspections = "";
  let url_inspections_add = "";
  let url_inspections_data = "";
  let url_vehicle_inspections = "";

  let url_home_reports = "";

  //let url_updateuser = "";
  switch (context.user.role) {
    case "SuperAdmin":
      permission_role = SuperAdmin;
      url_drivers = DriversSA;
      url_vehicles = VehiclesSA;
      url_vehicles_add = AddVehicleSA;
      url_vehicles_data = DataVehicleSA;
      url_vehicles_update = UpdateVehicleSA;
      url_user_add = AddUserSA;
      url_user_data = DataUserSA;
      url_user_update = UpdateUserSA;
      url_home_reports = HomeReports;
      break;
    case "Admin":
      permission_role = Admin;
      let campaigns = ['flotas.idoneo.es/excel_combustible', 'flotas.idoneo.es/excel_mantenimiento', 'flotas.idoneo.es/ebook_tco'];
      if (context.user.campaign != null && campaigns.includes(context.user.campaign)) {
        url_onboarding = GestorCampaign;
      } else {
        url_onboarding = GestorOnboarding;
      }

      url_infoOnboarding = GestorInfoOnboarding;
      url_road = RoadAdmin;
      url_doc = DocumentationAdmin;
      url_carsharing = CarsharingAdmin;
      url_drivers = Drivers;
      url_vehicles = Vehicles;
      url_vehicles_add = AddVehicle;
      url_vehicles_data = DataVehicle;
      url_vehicles_update = UpdateVehicle;
      url_vehicle_fines = VehicleFinesA;
      url_user_add = AddUser;
      url_user_data = DataUser;
      url_user_update = UpdateUser;
      url_fines = FinesA;
      url_fines_add = FinesAddA;
      url_fines_data = FinesDataA;
      url_contact = Contact;
      url_failure = FailureAdmin;
      //descomentar
      url_wsdates = WorkshopDatesA;
      url_wsdatesVehicle = WorkshopDatesVehicle;
      url_wsdates_data = DataWorkshopDateA;
      url_wsdates_add = AddWorkshopDateA;
      //url_updateuser = "/user/:id/updateuser";
      url_inspections = InspectionsA;
      url_inspections_add = AddInspectionA;
      url_inspections_data = DataInspectionA;
      url_vehicle_inspections = VehicleInspectionsA;
      url_home_reports = HomeReports;
      break;
    case "Driver":
      user_id = context.user.id;
      permission_role = Driver;
      url_onboarding = Onboarding;
      url_infoOnboarding = InfoOnboarding;
      url_road = Road;
      url_doc = Documentation;
      url_carsharing = CarsharingDriver;
      url_vehicle_fines = VehicleFinesD;
      url_fines_add = FinesAddD;
      url_fines_data = FinesDataD;
      url_user_update = UpdateUser;
      url_contact = ContactDriver;
      url_failure = FailureUser;
      url_wsdates_data = DataWorkshopDateD;

      // url_updateuser = "/profile/updateuser";
      url_inspections_data = DataInspectionD;
      url_vehicle_inspections = VehicleInspectionsD;
      url_home_reports = HomeReportsDriver;
      break;
    default:
      permission_role = Driver;
      url_onboarding = Onboarding;
      url_infoOnboarding = InfoOnboarding;
      url_road = Road;
      url_doc = Documentation;
      url_carsharing = CarsharingDriver;
      url_failure = FailureUser;
      url_home_reports = HomeReportsDriver;
      //url_updateuser = "/profile/updateuser";
      break;
  }
  return (
    <BrowserRouter>
      <Switch>
        {getToken ? (
          context.user.status === 99 ? (
            <Redirect exact from="/" to="/onboarding" />
          ) : context.user.status === 1 ? (
            <Redirect exact from="/" to="/home" />
          ) : context.user.status === 2 ? (
            <Unsubscribed></Unsubscribed>
          ) : context.user.status === 0 ? (
            <>
              <Loading className="d-flex justify-content-center mt-5" />
              {ErrorSwal(
                401,
                "Cuenta bloqueada",
                "La cuenta ha sido bloqueada ",
                "error",
                JSONData,
                intl,
                context
              )}
            </>
          ) : (
            <Loading className="d-flex justify-content-center mt-5" />
          )
        ) : loginopass === "pass" ? (
          <Repass />
        ) : href === "/confirmpass" ? (
          <ConfirmPass />
        ) : (
          <Login setToken={setToken} />
        )}
        {/*Especific*/}
        <PrivateRoute
          exact
          path="/onboarding"
          component={url_onboarding}
          notfound={NotFound}
          rolout={permission_role}
        />
        <PrivateRoute
          exact
          path="/infoOnboarding"
          component={url_infoOnboarding}
          notfound={NotFound}
          rolout={permission_role}
        />
        <PrivateRoute
          exact
          path="/road"
          component={url_road}
          notfound={NotFound}
          rolout={permission_role}
        />
        {/*Especific*/}
        <PrivateRoute
          exact
          path="/home"
          component={Home}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          exact
          path="/dashboard"
          component={Dashboard}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          exact
          path="/configuration"
          component={Configuration}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          exact
          path="/idoneo"
          component={IdoneoContact}
          notfound={NotFound}
          rolout={context.idoneoContact.id != null ? Admin : ""}
        />
        <PrivateRoute
          exact
          path="/help"
          component={Help}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          exact
          path="/help/video/:id"
          component={HelpVideo}
          notfound={NotFound}
          rolout={All}
        />
        {/*<PrivateRoute
          exact
          path="/prueba/:id"
          component={Prueba}
          notfound={NotFound}
          rolout={All}
        /> */}
        <PrivateRoute
          exact
          path="/manager"
          component={Manager}
          notfound={NotFound}
          rolout={Driver}
        />
        <PrivateRoute
          exact
          path="/latestactivity"
          component={AccountActivityList}
          notfound={NotFound}
          rolout={Admin}
        />
        {/*<PrivateRoute
          exact
          path="/refueling/list"
          component={RefuelingList}
          notfound={NotFound}
          rolout={Driver}
        />*/}
        <PrivateRoute
          exact
          path="/refueling/:id?"
          component={Refueling}
          notfound={NotFound}
          rolout={All}
        />
        {/*<PrivateRoute
          path="/refuelings/vehicle/:id"
          component={RefuelingsListVehicle}
          notfound={NotFound}
          rolout={Admin}
      />*/}
        {/*<PrivateRoute
          path="/refuelings/user/:id"
          component={RefuelingsListUser}
          notfound={NotFound}
          rolout={All}
    />*/}
        {/*<PrivateRoute
          exact
          path="/mileage/list"
          component={MileageList}
          notfound={NotFound}
          rolout={Driver}
        />*/}
        <PrivateRoute
          exact
          path="/mileage/:id?"
          component={Mileage}
          notfound={NotFound}
          rolout={All}
        />
        {/*<PrivateRoute
          path="/mileages/vehicle/:id"
          component={MileagesListVehicle}
          notfound={NotFound}
          rolout={Admin}
      />*/}
        {/*<PrivateRoute
          path="/mileages/user/:id"
          component={MileagesListUser}
          notfound={NotFound}
          rolout={All}
    />*/}
        <PrivateRoute
          exact
          path="/drive"
          component={FicharVehicle}
          notfound={NotFound}
          rolout={All}
        />
        <Redirect from="/ficharvehicles/:id?" to="/driveVehicleQr/:id?" />
        <PrivateRoute
          exact
          path="/driveVehicleQr/:id?"
          component={DriveVehicleQr}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          exact
          path="/vehicles"
          component={url_vehicles}
          notfound={NotFound}
          rolout={["Admin", "SuperAdmin"]}
        />
        <PrivateRoute
          exact
          path="/user/vehicles"
          component={MyVehicles}
          notfound={NotFound}
          rolout={Driver}
        />
        <PrivateRoute
          path="/user/vehicles/data/:id"
          component={MyDataVehicle}
          notfound={NotFound}
          rolout={Driver}
        />
        {/*<PrivateRoute
          path="/admin/vehicles/data/:id"
          component={MyDataVehicleAdmin}
          notfound={NotFound}
          rolout={Admin}
        />*/}
        <PrivateRoute
          path="/resumen/consumo/:id"
          component={ResumenConsumo}
          notfound={NotFound}
          rolout={Driver}
        />
        <PrivateRoute
          exact
          path="/vehicle/add"
          component={url_vehicles_add}
          notfound={NotFound}
          rolout={["Admin", "SuperAdmin"]}
        />
        <PrivateRoute
          exact
          path="/vehicle/assign/:id?"
          component={AssignVehicle}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/failure/data/:id"
          component={FailuresData}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/vehicle/failures/history/:id"
          component={FailuresListVehicle}
          notfound={NotFound}
          rolout={Admin}
        />
        {/*<PrivateRoute
          path="/vehicle/equipments/:id"
          component={EquipmentsListVehicle}
          notfound={NotFound}
          rolout={Admin}
        />*/}
        <PrivateRoute
          exact
          path="/user/data/:id"
          component={url_user_data}
          notfound={NotFound}
          rolout={["Admin", "SuperAdmin"]}
        />
        <PrivateRoute
          path="/user/history/:id"
          component={HistoryUser}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/vehicle/data/:id"
          component={url_vehicles_data}
          notfound={NotFound}
          rolout={["Admin", "SuperAdmin"]}
        />
        <PrivateRoute
          path="/vehicle/renting/data/:id"
          component={RentingDataVehicle}
          notfound={NotFound}
          rolout={["Admin", "SuperAdmin"]}
        />
        <PrivateRoute
          path="/driver/assign/:id"
          component={AssignDriver}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/carsharing/addbooking"
          component={AddBooking}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          path="/carsharing/databooking/:id/start"
          component={FormStartBooking}
          notfound={NotFound}
          rolout={Driver}
        />
        <PrivateRoute
          path="/carsharing/databooking/:id"
          component={DataBooking}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          path="/carsharing/dataAvailability/:id?"
          component={CarsharingAdmin}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/carsharing/addCarsharing/:id?"
          component={AddCarsharing}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/carsharing/editCarsharing/:id?"
          component={EditCarsharing}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/carsharing/addAvailability/:id?"
          component={AddAvailability}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/carsharing/editAvailability/:id?"
          component={EditAvailability}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/carsharing"
          component={url_carsharing}
          notfound={NotFound}
          rolout={Driver}
        />
        <PrivateRoute
          path="/vehicle/history/:id"
          component={HistoryVehicle}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          path="/vehicle/refuel/:id"
          component={DriverRefuel}
          notfound={NotFound}
          rolout={Driver}
        />
        {/*<PrivateRoute
          path="/vehicle/seconds/:id"
          component={SecondsVehicles}
          notfound={NotFound}
          rolout={All}
        />*/}
        <PrivateRoute
          exact
          path="/profile"
          component={Profile}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          exact
          path="/profile/license"
          component={License}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          exact
          path="/profile/personal"
          component={Personal}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          exact
          path="/profile/update/password"
          component={UpdatePassword}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          exact
          path={"/profile/:id/document"}
          component={UserDni}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          exact
          //path={url_updateuser}
          path="/user/update/:id"
          component={url_user_update}
          notfound={NotFound}
          //rolout={["Admin", "SuperAdmin"]}
          rolout={All}
        />
        <PrivateRoute
          exact
          path="/vehicle/update/:id"
          component={url_vehicles_update}
          notfound={NotFound}
          rolout={["Admin", "SuperAdmin"]}
        />
        <PrivateRoute
          exact
          path={"/user/" + user_id + "/dni"}
          component={UserDni}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          exact
          path={"/user/" + user_id + "/updateidcard"}
          component={UpdateIdentityCard}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          exact
          path={"/user/" + user_id + "/license"}
          component={UserLicense}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path={"/user/:id/updateLicense"}
          component={UpdateLicense}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          path="/consumo/vehicle"
          component={CVehicle}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/consumo/mileage"
          component={CMileage}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/consumo/refueling"
          component={CRefueling}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          exact
          path="/consumo/data/:id"
          component={DataConsumo}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          path="/consumo/:id?"
          component={Consumo}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          path="/failure/edit/:id?"
          component={FailureEdit}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          path="/failure/documents/:id?"
          component={FailureDocuments}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          path="/failure/upload/documentation/:id?"
          component={AddFailureDocuments}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          path="/failure/gallery/:id?"
          component={FailureGallery}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          path="/failure/images/:id?"
          component={FailureImages}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          path="/failure/upload/images/:id?"
          component={AddFailureImages}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          path="/failure/:id?"
          component={url_failure}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          path="/failuresFleet"
          component={FailuresListFleet}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          exact
          path="/user/add"
          component={url_user_add}
          notfound={NotFound}
          rolout={["Admin", "SuperAdmin"]}
        />
        <PrivateRoute
          exact
          path="/drivers"
          component={url_drivers}
          notfound={NotFound}
          rolout={["Admin", "SuperAdmin"]}
        />
        <PrivateRoute
          exact
          path="/road/car/:id"
          component={RoadCar}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          exact
          path="/vehicle/upload/documentation/:id?"
          component={UploadDocumentation}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          exact
          path="/vehicle/documentation/:id?"
          component={url_doc}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          exact
          path="/vehicle/fines/:id?"
          component={url_vehicle_fines}
          notfound={NotFound}
          rolout={["Driver", "Admin"]}
        />
        <PrivateRoute
          exact
          path="/vehicle/inspections/:id?"
          component={url_vehicle_inspections}
          notfound={NotFound}
          rolout={["Driver", "Admin"]}
        />
        <PrivateRoute
          exact
          path="/vehicle/usages/:id?"
          component={UsagesHistoryA}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          exact
          path="/contact"
          component={url_contact}
          notfound={NotFound}
          rolout={["Driver", "Admin"]}
        />
        <PrivateRoute
          exact
          path="/contact/car/:id"
          component={ContactCar}
          notfound={NotFound}
          rolout={["Driver", "Admin"]}
        />
        <PrivateRoute
          exact
          path="/contact/add"
          component={AddContact}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          exact
          path="/contact/update/:id"
          component={UpdateContact}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/contact/assign/:id?"
          component={AssignContact}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          exact
          path="/contact/data/:id/:vehicle_id?"
          component={DataContact}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          exact
          path="/contact/admin/data/:id"
          component={DataContactA}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          exact
          path="/road/add"
          component={AddRoad}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          exact
          path="/road/data/:id"
          component={DataRoad}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          exact
          path="/qr/generate/user/:id?"
          component={GenerateUserQr}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          exact
          path="/qr/generate/:id?/:type?"
          component={GenerateQr}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/notifications"
          component={Notifications}
          notfound={NotFound}
          rolout={Driver}
        />
        <PrivateRoute
          path="/fines/add/:id?"
          component={url_fines_add}
          notfound={NotFound}
          rolout={["Driver", "Admin"]}
        />
        <PrivateRoute
          path="/fines/:id"
          component={url_fines_data}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          path="/fines"
          component={url_fines}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/workshopdates/add/"
          component={url_wsdates_add}
          notfound={NotFound}
          //rolout={["Driver", "Admin"]}
          rolout={Admin}
        />
        <PrivateRoute
          path="/workshopdates/:id"
          component={url_wsdates_data}
          notfound={NotFound}
          rolout={["Driver", "Admin"]}
        />
        <PrivateRoute
          path="/vehicle/workshopdates/:id"
          component={url_wsdatesVehicle}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/workshopdates"
          component={url_wsdates}
          notfound={NotFound}
          //rolout={["Driver", "Admin"]}
          rolout={Admin}
        />
        <PrivateRoute
          path="/inspections/add/"
          component={url_inspections_add}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/inspections/:id"
          component={url_inspections_data}
          notfound={NotFound}
          rolout={["Driver", "Admin"]}
        />
        <PrivateRoute
          path="/inspections"
          component={url_inspections}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/expenses/update/:id"
          component={UpdateExpense}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          path="/expenses/data/:id"
          component={DataExpenses}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          path="/expenses/add/:id?"
          component={AddExpense}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          path="/expenses/:id"
          component={Expenses}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          exact
          path="/excelimport"
          component={ExcelImport}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          exact
          path="/excelimport/tableexample/:type"
          component={TableExample}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          exact
          path="/importer/:type"
          component={Importer}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/notificationsAdmin"
          component={NotificationsAdmin}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/alerts"
          component={Alerts}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/smartcar/:carid/info"
          component={SmartCarDataInfo}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/smartcar/terms/:id?"
          component={PlanAndTerms}
          notfound={NotFound}
          rolout={Admin}
        />
        {/* REPORTS */}
        <PrivateRoute
          path="/reports/vehicles/fuel"
          component={VehiclesFuelReports}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/reports/home"
          component={url_home_reports}
          notfound={NotFound}
          rolout={All}
        />
        <PrivateRoute
          path="/reports/users"
          component={UserReports}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/reports/vehicles/total-cost"
          component={VehicleReports}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/reports/vehicles/cost-evolution"
          component={CostEvolution}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/reports/vehicles/cost-per-km"
          component={CostPerKm}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/reports/vehicles/use-vehicles"
          component={UseVehicles}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/reports/failures"
          component={FailureReports}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/reports/repair"
          component={RepairReports}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/reports/fuel"
          component={FuelReports}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/reports/inspections"
          component={InspectionsReports}
          notfound={NotFound}
          rolout={Admin}
        />
        <PrivateRoute
          path="/reports/driver/fuel"
          component={DriverFuel}
          notfound={NotFound}
          rolout={Driver}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
