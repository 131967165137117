// ============================
//  Libraries Imports
// ============================
import styled from 'styled-components'
import { Form } from 'formik'

// ============================
//  Customs Imports
// ============================
import Device from '../Common/breakPoint'

// ============================
//  Styled Components
// ============================
const StyledForm = styled(Form)`

  @media only screen and ${Device.sm}{
    width: 40vw;
  }

  @media only screen and ${Device.lg}{
    width: 30vw;
  }

  @media only screen and ${Device.xl}{
    width: auto;
  }
`
// ============================
//  Principal Component
// ============================
const LoginForm = ({ children, ...props }) => {

    return (
        <StyledForm {...props}>
            {children}
        </StyledForm>
    )
}

export default LoginForm